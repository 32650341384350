import React from 'react';
import { ImageFile } from '~/data/types/images';

const ContactUsBubbleIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
  color = '#0E8482'
}) => {
  return (
    <Component
      width="20"
      height="20"
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8931 15.8889C13.3463 18.436 9.57514 18.9863 6.48901 17.5591C6.03341 17.3756 5.6599 17.2274 5.3048 17.2274C4.31573 17.2333 3.08463 18.1923 2.44479 17.5532C1.80495 16.9133 2.76471 15.6812 2.76471 14.6862C2.76471 14.3311 2.62234 13.9642 2.43893 13.5077C1.01102 10.4221 1.56208 6.64964 4.10887 4.1034C7.35999 0.851089 12.642 0.851089 15.8931 4.10256C19.1501 7.3599 19.1442 12.6375 15.8931 15.8889Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.283 10.3385H13.2905"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.94221 10.3385H9.94971"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.60139 10.3385H6.60889"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default ContactUsBubbleIcon;
