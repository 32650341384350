import React from 'react';
import { ImageFile } from '~/data/types/images';

const YellowTapeMobileIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      viewBox="0 0 375 251"
    >
      <path
        d="M-18 288.602C23.8613 263.925 104.197 185.206 79.3627 96.8561C63.9639 58.2421 9.72261 58.3115 -1.90367 99.0265C-7.92501 120.113 3.51006 142.395 21.5696 154.835C67.4575 186.444 148.541 198.314 223.5 192.102C314 184.602 399.5 96.8561 399.5 6.10156"
        stroke="#FFE500"
        strokeWidth="11"
        strokeLinecap="round"
      />
    </Component>
  );
};

export default YellowTapeMobileIcon;
