import { COLORS } from '~/data/ui/colors';
import { FONT_SIZES } from '~/data/ui/fontSizes.data';
import theme from '~/theme';
import HomePageHeroDecor from '~/assets/images/HomePageHeroDecor.svg';
import HomePageHeroDecorRight from '~/assets/images/HomePageHeroDecorRight.svg';
import HomePageHeroMobileDecor from '~/assets/images/HomePageHeroMobileDecor.svg';
import HomePageHeroMobileDecorRight from '~/assets/images/HomePageHeroMobileDecorRight.svg';

export const root = {
  marginTop: '24px',
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    marginTop: '75px',
  },
};

export const contentContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '12px',
  padding: '0px 20px 0px',
  [theme.breakpoints.up('md')]: {
    gap: '22px',
  },
};

export const buttonsContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '4px',
  [theme.breakpoints.up('md')]: {
    gap: '20px',
  },
};

export const buttonStyles = {
  maxWidth: '120px',
  maxHeight: '39px',
  [theme.breakpoints.up('md')]: {
    maxWidth: '142px',
    maxHeight: '45px',
  },
};

export const buttonTypographyStyles = {
  justifySelf: 'center',
  width: '100%',
  margin: 'auto',
  flex: 1,
  fontSize: '16px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
};

export const transparentButtonStyles = {
  height: 'unset',
  width: 'unset',
  borderRadius: 'unset',
  borderBottom: `2.5px solid ${COLORS.yellow.main}`,
  marginTop: '5px',
  [theme.breakpoints.up('md')]: {
    marginTop: '-2px',
  },
};

export const transparentButtonTextStyles = {
  lineHeight: 'normal',
  fontSize: FONT_SIZES['m'],
};

export const titleStyles = {
  fontSize: FONT_SIZES['xl_title'],
  textAlign: 'center',
  lineHeight: '39px',
  paddingTop: '75px',
  zIndex: 1,
  whiteSpace: 'pre-wrap',
  [theme.breakpoints.up('md')]: {
    fontSize: FONT_SIZES['xxl'],
    paddingTop: '75px',
  },
};

export const descriptionStyles = {
  fontSize: FONT_SIZES['s'],
  width: '100%',
  maxWidth: '400px',
  textAlign: 'center',
  lineHeight: 'unset',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    fontSize: FONT_SIZES['l'],
    maxWidth: '600px',
  },
};

export const desktopTitleStyles = {
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'inline-block',
  },
};

export const mobileTitleStyles = {
  display: 'inline-block',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
};

export const heroLeftDecorStyles = {
  background: `url(${HomePageHeroDecor.src}) no-repeat left center`,
  backgroundSize: 'contain',
  position: 'absolute',
  left: 0,
  width: 'clamp(200px, 20vw, 358px)',
  aspectRatio: '317/348',
  height: 'unset',
  '@media (max-width: 750px)': {
    background: `url(${HomePageHeroMobileDecor.src}) no-repeat left center`,
    backgroundSize: 'contain',
    aspectRatio: '39/96',
    position: 'absolute',
    left: 0,
    maxWidth: '50px',
    height: 'unset',
    top: '20px',
  },
} as const;

export const heroRightDecorStyles = {
  background: `url(${HomePageHeroDecorRight.src}) no-repeat center center`,
  backgroundSize: 'contain',
  position: 'absolute',
  right: '-20px',
  top: '-10px',
  width: 'clamp(200px, 20vw, 294px)',
  aspectRatio: '294 / 667',
  height: 'unset',
  '@media (max-width: 750px)': {
    background: `url(${HomePageHeroMobileDecorRight.src}) no-repeat right center`,
    backgroundSize: 'contain',
    position: 'absolute',
    right: 0,
    bottom: 0,
    transform: 'translateY(75%)',
    width: 'clamp(180px, 20vw, 193px)',
    height: 'unset',
  },
} as const;

export const customBottomButtonStyles = {
  gap: '8px',
};
