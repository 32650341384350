import React from 'react';
import { ImageFile } from '~/data/types/images';

const YellowTapeIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      viewBox="0 0 1336 612"
    >
      <path
        d="M11 -7C23.5068 84.925 100.044 301.335 253.177 301.335C349.915 301.335 381.874 264.611 372.605 218.928C360.761 160.559 284.685 161.376 270.021 219.103V219.103C253.759 283.124 300.873 539.203 1001 525C1270 540.5 706.578 574.995 777.5 620C849.819 665.891 1246.5 510.5 1326 641.5"
        stroke="#FFE500"
        strokeWidth="22"
      />
    </Component>
  );
};

export default YellowTapeIcon;
