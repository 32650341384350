import { Box } from '@mui/material';
import {
  StyledIcon,
  StyledImage,
  StyledSection,
  StyledParagraph,
  buttonStyles,
  contentContainerStyles,
  imageContainerStyles,
  yellowTapeContainerStyles,
  yellowTapeStyles,
  StyledTitle,
} from './useStyles';
import YellowTapeIcon from '~/assets/images/jsx/YellowTape';
import YellowTapeMobileIcon from '~/assets/images/jsx/YellowTapeMobile';
import { SearchByNeedResponseType } from '~/data/types/bannerCard/bannerCard.types';
import { FONT_SIZES } from '~/data/ui/fontSizes.data';
import { TEXT_COLORS } from '~/data/ui/typograpy.constants';
import Button from '../Button';
import { BUTTON_SIZES, BUTTON_TYPES } from '~/data/constants';
import ArrowLeftIcon from '~/assets/icons/jsx/ArrowLeft';
import { useIsMobile } from '~/hooks/useIsMobile';
import { IEvent, useEvents } from '~/hooks/useEvents';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from '~/redux-toolkit/store/hooks';
import { RootState } from '~/redux-toolkit/store/store';
import { carTypeSelectionUpdate } from '../SearchFlowV2Steps/ChooseCarTypeStep/utils';
import {
  resetProcess,
  setActiveStep,
  setIsSearchFromHomePage,
} from '~/redux-toolkit/slices/searchFlowSlice';
import { initSelectedFiltersState } from '~/redux-toolkit/slices/searchFiltersSlice';
import { CAR_FLOW_TYPES } from '~/data/consts';
import { ROUTES } from '~/data/routes';
import { useImageKitS3 } from '~/hooks/useImageKitS3';

type SearchByNeedsProps = {
  data: SearchByNeedResponseType;
  event: IEvent;
  lobby?: CAR_FLOW_TYPES;
};

const SearchByNeeds = ({ data, event, lobby }: SearchByNeedsProps) => {
  const {
    title,
    titleSize,
    description,
    descriptionSize,
    buttonTitle,
    buttonUrlLink,
    icon,
    image,
  } = data;
  const dispatch = useAppDispatch();
  const events = useEvents();
  const isMobile = useIsMobile();
  const router = useRouter();
  const { carsDeal, activeStep } = useAppSelector(
    (state: RootState) => state.searchFlow,
  );
  const imageUrl = useImageKitS3(image.url);
  const iconUrl = useImageKitS3(icon.url);
  const handleOnClick = () => {
    if (buttonUrlLink) {
      if (router.asPath === ROUTES.HOMEPAGE) {
        dispatch(setIsSearchFromHomePage(true));
      } else {
        dispatch(setIsSearchFromHomePage(false));
      }

      router.push(buttonUrlLink);
      if (lobby) {
        carTypeSelectionUpdate(dispatch, carsDeal, lobby);
        dispatch(setActiveStep(activeStep + 1));
      } else {
        dispatch(setActiveStep(0));
        dispatch(resetProcess());
      }
      dispatch(initSelectedFiltersState());
    }
    events.sendEvent(
      {
        action: event.action,
        category: event.category,
      },
      event.data || {},
    );
  };
  return (
    <StyledSection>
      <Box sx={yellowTapeContainerStyles}>
        {isMobile ? (
          <YellowTapeMobileIcon style={yellowTapeStyles} />
        ) : (
          <YellowTapeIcon style={yellowTapeStyles} />
        )}
      </Box>

      <Box
        sx={contentContainerStyles}
        aria-label={!buttonTitle ? `${title} ${description}` : ''}
      >
        {title && (
          <StyledTitle
            color={TEXT_COLORS.dark}
            fontSize={FONT_SIZES[titleSize || 'xl_title']}
            fontFamily="fontFamily"
            fontWeight="800"
            variant="h2"
          >
            {title}
          </StyledTitle>
        )}
        {description && (
          <StyledParagraph
            color={TEXT_COLORS.dark}
            fontSize={FONT_SIZES[descriptionSize || 'l']}
          >
            {description}
          </StyledParagraph>
        )}
        {buttonTitle && (
          <Button
            id="search-by-needs-cta"
            label={buttonTitle}
            size={BUTTON_SIZES.FIT}
            type={BUTTON_TYPES.MAIN}
            icon={<ArrowLeftIcon />}
            isIconOnRight
            customStyle={buttonStyles}
            onClickFunc={handleOnClick}
            ariaLabel={`${title} ${description} ${buttonTitle}`}
          />
        )}
        <Box sx={imageContainerStyles}>
          {image && (
            <StyledImage
              src={imageUrl}
              alt=""
              height={image.height}
              width={image.width}
              ratio={`${image.width} / ${image.height}`}
            />
          )}
          {icon && (
            <StyledIcon
              src={iconUrl}
              alt=""
              height={icon.height}
              width={icon.width}
              ratio={`${icon.width} / ${icon.height}`}
            />
          )}
        </Box>
      </Box>
    </StyledSection>
  );
};

export default SearchByNeeds;
