import { Dispatch } from '@reduxjs/toolkit';
import { CAR_FLOW_TYPES } from '~/data/consts';
import {
  addSelectedFlow,
  CarDeal,
  setSelectedCarDeal,
  updateFinishSearchProcess,
} from '~/redux-toolkit/slices/searchFlowSlice';

export const carTypeSelectionUpdate = (
  dispatch: Dispatch,
  carsDeal: CarDeal[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: CAR_FLOW_TYPES,
) => {
  dispatch(addSelectedFlow(type));
  dispatch(updateFinishSearchProcess({ selectedCarType: !type }));
  const selected = carsDeal.find((car) => car.carDeal === type);
  dispatch(setSelectedCarDeal(selected as CarDeal));
};
