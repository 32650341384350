import { Chip, styled } from '@mui/material';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

export const root = {
  width: '100%',
  maxWidth: '510px',
  marginTop: '16px',
  [theme.breakpoints.up('md')]: {
    marginTop: '0px',
    maxWidth: '500px',
  },
};

export const listContainer = {
  border: 'none',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: '8px',
  columnGap: '9px',
  flexWrap: 'wrap',
  margin: '0 auto',
  [theme.breakpoints.up('md')]: {
    width: 'fit-content',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
};

export const listItemStyles = {
  width: 'unset',
};

export const chipIconStyle = {
  height: '14px',
  width: '14px',
};

export const StyledChip = styled(Chip)({
  height: '39px',
  direction: 'ltr',
  alignItems: 'center',
  padding: '8px',
  border: `1px solid transparent`,
  borderRadius: '58px',
  '&:hover': {
    background: COLORS.white.main,
    border: `1px solid ${COLORS.primary.main}`,
  },
});

export const chipStyle = {
  backgroundColor: COLORS.primary.light2,
  fontSize: '16px',
  fontWeight: '400',
  '&:focus-visible': {
    backgroundColor: `${COLORS.primary.light2}`,
    border: `1px solid ${COLORS.black.dark}`,
  },
};
