import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { FONT_SIZES } from '~/data/ui/fontSizes.data';
import theme from '~/theme';

export const StyledSection = styled('section')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  paddingTop: '70px',
  overflow: 'hidden',
  [theme.breakpoints.down('md')]: {
    paddingTop: '51px',
  },
});

export const StyledParagraph = styled(Typography)<{
  color: string;
  fontWeight?: string;
  fontSize: string;
}>`
  ${(props) => ({
    fontSize: props.fontSize,
    color: props.color,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: FONT_SIZES['s'],
    },
  })}
`;

export const StyledTitle = styled(Typography)<{
  color: string;
  fontWeight?: string;
  fontSize: string;
}>`
  ${(props) => ({
    fontSize: props.fontSize,
    color: props.color,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: FONT_SIZES['l'],
    },
  })}
`;

export const StyledImage = styled('img')<{
  ratio: string;
}>`
  ${(props) => ({
    aspectRatio: props.ratio,
    maxWidth: '100%',
    width: '100%',
    height: 'unset',
    display: 'block',
  })}
`;

export const StyledIcon = styled('img')<{
  ratio: string;
}>`
  ${(props) => ({
    aspectRatio: props.ratio,
    maxWidth: 'clamp(134px, 20vw ,232px)',
    height: 'unset',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateX(50%) translateY(-50%)',
  })}
  @media only screen and (max-width: 365px) {
    display: none;
  }
`;

export const imageContainerStyles = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
};

export const buttonStyles = {
  margin: '20px 0 50px',
  gap: '8px',
  [theme.breakpoints.down('md')]: {
    margin: '20px 0 40px',
  },
};

export const contentContainerStyles = {
  width: '100%',
  maxWidth: 'clamp(270px, 60vw ,580px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 'auto',
  paddingBottom: '11px',
};

export const yellowTapeStyles = {
  width: '100%',
  height: '100%',
  display: 'block',
};

export const yellowTapeContainerStyles = {
  width: '1336px',
  position: 'absolute',
  maxWidth: '1336px',
  height: '100%',
  zIndex: -1,
  top: 0,
  [theme.breakpoints.down('md')]: {
    top: 'unset',
    bottom: 0,
    transform: 'unset',
    height: 'unset',
    width: '100%',
  },
};
