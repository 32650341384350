import React from 'react';
import { ImageFile } from '~/data/types/images';

const starQuickSearchIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.95291 2.06661L8.56765 4.36239C8.72596 4.5878 8.98634 4.72151 9.26477 4.71973L12.1042 4.70292C12.8058 4.69907 13.2039 5.49035 12.7784 6.04176L11.0582 8.27446C10.8892 8.494 10.8402 8.78048 10.9271 9.04159L11.8104 11.6969C12.0284 12.3539 11.3877 12.9761 10.7261 12.7491L8.04866 11.8335C7.78617 11.7437 7.4945 11.7874 7.26921 11.9504L4.97824 13.6112C4.4123 14.0224 3.61726 13.6155 3.63362 12.9234L3.69829 10.1258C3.70481 9.85071 3.57395 9.5912 3.34795 9.43091L1.04842 7.80113C0.479915 7.39879 0.628352 6.52554 1.30019 6.3234L4.01959 5.50645C4.28628 5.42647 4.49669 5.22214 4.5819 4.9602L5.45135 2.29183C5.66655 1.63202 6.55361 1.49897 6.95291 2.06661Z"
        fill="#00D6D1"
        stroke="black"
        strokeWidth="0.876563"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default starQuickSearchIcon;
