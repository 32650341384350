import { Icon, List, ListItem } from '@mui/material';
import Star from '~/assets/icons/jsx/starQuickSearch';
import {
  StyledChip,
  chipIconStyle,
  chipStyle,
  listContainer,
  listItemStyles,
  root,
} from './useStyles';
import {
  QuickSearchContentType,
  SearchChips,
} from '~/data/QuickSearch/quickSearchType';
import { Box } from '@mui/system';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import React, { useCallback } from 'react';
import Link from 'next/link';

interface ChipListProps {
  selectedSearchType: QuickSearchContentType;
  customStyles?: React.CSSProperties;
}

const ChipList = ({ selectedSearchType, customStyles }: ChipListProps) => {
  const events = useEvents();

  const handleOnClickChip = useCallback(
    (chip: SearchChips) => {
      events.sendEvent(
        {
          category: ECategoryEvent.SEARCH,
          action: EActionEvent.POPULAR_SEARCH_CLICKED,
        },
        {
          businessLine: selectedSearchType?.searchType,
          chipName: chip.title,
        },
      );
    },
    [events, selectedSearchType?.searchType],
  );
  return (
    <Box sx={{ ...root, ...customStyles }}>
      <List sx={listContainer} disablePadding>
        {selectedSearchType?.searchChips?.map((chip, index) => (
          <ListItem key={`chip${chip.id}`} disablePadding sx={listItemStyles}>
            {chip.urlLink && (
              <Link href={chip.urlLink} passHref>
                <StyledChip
                  label={chip.title}
                  onClick={() => {
                    handleOnClickChip(chip);
                  }}
                  tabIndex={-1}
                  id={index === 0 ? 'main-content' : ''}
                  sx={chipStyle}
                  icon={
                    chip.isSpecial ? (
                      <Icon component={Star} sx={chipIconStyle} />
                    ) : (
                      <div />
                    )
                  }
                />
              </Link>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default ChipList;
