import { useEffect, useState } from 'react';


export const useWindowWidth = () => {
  // Initialize with a default value that will be true or false depending on server-rendered layout.
  // This value will be immediately replaced on the client after the initial render.
  const [width, setWidth] = useState(0);

  useEffect(() => {
    // This check ensures that the code only runs on the client-side.
    if (typeof window !== 'undefined') {
      setWidth(window.innerWidth);

      const handleWindowResize = () => setWidth(window.innerWidth);
      window.addEventListener('resize', handleWindowResize);

      return () => window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  return width;
};
