import { styled } from '@mui/material';
import { CSSProperties } from 'react';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

export const WIDER_MOBILE_WIDTH = 780;

const classes = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '52px 0 28px',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      padding: '84px 48px 66px',
    },
  },
  content: {
    width: '100vw',
    position: 'relative',
    display: 'flex',
    gap: '8px',
    flexDirection: 'column',
    alignItems: 'center',

    '& .arrows': {
      display: 'none',
    },
    [theme.breakpoints.up(WIDER_MOBILE_WIDTH)]: {
      '& .arrows': {
        display: 'flex',
      },
    },
    [theme.breakpoints.up('md')]: {
      width: '900px',
    },
    [theme.breakpoints.up(1010)]: {
      width: '998px',
    },
    '& .slick-next': {
      display: 'none !important',
    },
    '& .slick-prev': {
      display: 'none !important',
    },
  },
  title: {
    fontSize: '24px',
    display: 'flex',
    alignItems: 'stretch',
    gap: '3px',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px ',
    },
  },
  slides: {
    width: '100%',
  },
  arrowsContainer: {
    //
  },
  arrow: {
    position: 'absolute',
    transform: 'translate(50%)',
    top: '50%',
    display: 'flex',
    zIndex: 100,
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    background: 'rgba(69, 68, 68, 0.7)',
    border: `1px solid ${COLORS.white.main}`,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& path': {
      stroke: COLORS.white.main,
    },
    [theme.breakpoints.down(WIDER_MOBILE_WIDTH)]: {
      display: 'none',
    },
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        background: 'rgba(255, 254, 254, 0.5)',
        border: `1px solid ${COLORS.primary.main}`,
        color: COLORS.black.main,
        '& path': {
          stroke: COLORS.black.main,
        },
      },
    },
    '&:active': {
      [theme.breakpoints.up('md')]: {
        background: `${COLORS.primary.main} !important`,
        border: `1px solid ${COLORS.primary.main}`,
        color: COLORS.black.main,
      },
    },
  },
  arrowRight: {
    right: '0',
    marginInlineStart: '0px',
    transform: 'scaleX(-1)',
    [theme.breakpoints.up(1070)]: {
      marginInlineStart: '-32px',
    },
    [theme.breakpoints.down(910)]: {
      marginInlineStart: '10px',
    },
  },
  arrowLeft: {
    left: '0',
    marginInlineEnd: '-20px',
    [theme.breakpoints.up(1070)]: {
      marginInlineEnd: '-49px',
    },
    [theme.breakpoints.down(910)]: {
      marginInlineEnd: '-10px',
    },
  },
  slide: {
    transition: 'all 0.20s linear',
  },
  activeSlide: {
    transform: 'scale(1)',
  },
  nextSlide: {
    transform: 'scale(0.9)',
  },
  leftSlide: {
    transform: 'scale(0.8)',
    marginInlineStart: '28px',
  },
  rightSlide: {
    transform: 'scale(0.8)',
    marginInlineStart: '-28px',
  },
};

export const HotFreesbe = styled('svg')({
  position: 'relative',
  transform: 'scale(.95)',
  top: '-3px',
  right: '-2px',
  [theme.breakpoints.up('md')]: {
    top: '2px',
    right: '0',
    transform: 'scale(1)',
  },
});

export const rootBoxStyle = (largeTopPadding: boolean) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '52px 0 28px',
  overflowX: 'hidden',
  [theme.breakpoints.up('md')]: {
    padding: '0px 48px 66px',
    paddingTop: largeTopPadding ? '90px' : '34px',
  },
});

export const initialContainer: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '382px',
  width: '100%',
};

export default classes;
